import clsx from "classnames";
import classes from "@/ui/JobCard/styles.module.scss";
import { DynamicText } from "@/ui/DynamicText";
import { trimText } from "@/functions/trimText";
import { Button, Stack } from "react-bootstrap";
import { useLocale } from "@/hooks/useLocale";
import { useLink } from "@/hooks/useLink";
import sourceflowEnums from "@/sourceflow.enums";

export const JobCard = ({
  className,
  title,
  salary_package,
  location,
  description,
  url_slug,
  categories,
  variant,
  btnVariant,
  applyBtn,
}) => {
  const locale = useLocale();
  const link = useLink();
  const sectors = categories.find((i) => i.id === sourceflowEnums.categories.sectors.id);
  const types = categories.find((i) => i.id === sourceflowEnums.categories.type.id);

  const externalSite = categories.find((c) => c.name == "Location")?.values?.[0]?.name;

  let jobUrl = link("jobs.item", url_slug);

  switch (externalSite) {
    case "UK":
      jobUrl = `https://www.gravitasgroup.co.uk/jobs/${url_slug}`;
      break;
    case "Germany":
      jobUrl = `https://www.gravitasgroup.de/jobs/${url_slug}`;
      break;
    case "Singapore":
      jobUrl = `https://www.gravitasgroup.com.sg/jobs/${url_slug}`;
      break;
    case "Netherlands":
      jobUrl = `https://www.gravitasgroup.nl/vacatures/${url_slug}`;
      break;
    case "Hong Kong and Mainland China":
      jobUrl = `https://www.gravitasgroup.com.hk/jobs/${url_slug}`;
  }

  return (
    <div className={clsx(className, classes.card, `variant-${variant}`, "bg-light px-3 py-4 p-md-5")}>
      {sectors && sectors.values[0] && (
        <div className={clsx(classes.card__sector, "mb-2 pb-1")} data-id={sectors.values[0].id}>
          {sectors.values[0].name}
        </div>
      )}
      <h3 className="mb-3" dangerouslySetInnerHTML={{ __html: title }} />
      {types &&
        (() => {
          const values = types.values.filter((i) => i.name.toLowerCase() !== "both");

          return (
            <dl>
              <dt>
                <DynamicText path={`job.card.type.label.${locale}`} />
              </dt>
              <dd dangerouslySetInnerHTML={{ __html: values.map((i) => i.name).join(", ") }} />
            </dl>
          );
        })()}
      <dl>
        <dt>
          <DynamicText path={`job.card.salary.label.${locale}`} />
        </dt>
        <dd dangerouslySetInnerHTML={{ __html: salary_package }} />
      </dl>
      <dl>
        <dt>
          <DynamicText path={`job.card.location.label.${locale}`} />
        </dt>
        <dd dangerouslySetInnerHTML={{ __html: location }} />
      </dl>
      <p className="mt-4 mb-5" dangerouslySetInnerHTML={{ __html: trimText(description, 140) }} />
      <Stack className="flex-row justify-content-between align-items-center">
        {applyBtn && (
          <a href={`${jobUrl}#apply`} target={variant === "internal" ? "_self" : "_blank"}>
            <DynamicText path={`job.card.apply.${locale}`} />
          </a>
        )}
        <Button variant={`outline-${btnVariant}`} href={jobUrl} target={variant === "internal" ? "_self" : "_blank"}>
          <DynamicText path={`job.card.button.${locale}`} />
        </Button>
      </Stack>
    </div>
  );
};

JobCard.defaultProps = {
  variant: "primary",
  btnVariant: "primary",
  applyBtn: false,
};
